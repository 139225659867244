body.front {
	#about {
		min-height: 50vh;
		overflow: hidden;

		&:before {
		    background-color: rgba(0,0,0,.7);
		}
	}

	#strategies {
		.icon-marker {
	    	font-size: 70px;
	    }
	}

	#firms {
	    background: $light-gray;

	    .firm-card {
	    	.logo-section {
			    height: 200px;
			    display: flex;
			    align-items: center;
			    justify-content: center;
			}
		}
	}

	.locations-map {
		margin-top: 0;
		border: none;
	}


	#contact {
		background: $light-gray;
	}
}