#header-logo-section {
	background-color: $light-gray;
}

#header-navigation {
	background-color: $primary-color;

	.top-bar {
		&, ul {
			background-color: transparent;
		}

		a {
			color: $white;

			&.is-active {
				background: darken($primary-color, 10%);
    			border-radius: 8px;
			}
		}		
	}

	.title-bar {
		a[data-toggle] {
			display: block;
			color: $white;
			text-align: center;
		}
	}
}