.background-image-container {
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.overlay {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.5);
        z-index: 1;
    }

    .overlay-content {
        position: relative;
        z-index: 2;
        color: #fefefe;
    }
}


.locations-map {
    width: 100%;
    height: 50vh;
    border: #ccc solid 1px;
    margin: 20px 0;

    img {
       max-width: inherit !important;
    }
}